import { Editor } from '@monaco-editor/react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    // Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Pagination,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useRef, useState } from 'react';
import { drawerWidth, gridSpacing } from 'store/constant';
import axiosServices from 'utils/axios';
import PropTypes from 'prop-types';
import COLOURS from 'utils/colors';
import MainCard from 'ui-component/cards/MainCard';
import CircularLoader from 'ui-component/CircularLoader';
import {
    CancelOutlined,
    Check,
    CheckCircleOutline,
    DescriptionOutlined,
    ErrorOutlineOutlined,
    Flag,
    KeyboardArrowRight,
    SmartDisplayOutlined
} from '@mui/icons-material';
import TimerCard from 'views/practice-page/timer-card';
import ReportDialog from '../ReportDialog';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch, useSelector } from 'store';
import MuiTooltip from '@mui/material/Tooltip';
import noSolutionImage from 'assets/images/app-images/no-solution.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import aiImage from 'assets/images/app-images/ai.png';
import MediaCard from 'views/practice-page/media-card';
import DocumentsCard from 'views/practice-page/documents-card';
import { Box } from '@mui/system';
import { IconRobot } from '@tabler/icons-react';

// tab content customize
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number
};

const Assessment = ({ questions, answers, mode, selectedElement, chapterMedia, assessmentId = null }) => {
    const [questionsToShow, setQuestionsToShow] = useState();
    const [currentQuestion, setCurrentQuestion] = useState();
    const [selectedOption, setSelectedOption] = useState(0);
    const [countdown, setCountdown] = useState();
    const [options, setOptions] = useState();
    const editorRef = useRef(null);
    const { user } = useAuth();
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
    const [isUnattempted, setIsUnattempted] = useState(true);
    const [noAnswerSelected, setNoAnswerSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSolutionLoading, setIsSolutionLoading] = useState(false);
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [solutionFromGPT, setSolutionFromGPT] = useState();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [notesOrVideos, setNotesOrVideos] = useState(0);
    const { drawerOpen } = useSelector((state) => state.menu);
    const [attemptedQuestionsCount, setAttemptedQuestionsCount] = useState(0);

    useEffect(() => {
        if (questions && questions.length) {
            setIsLoading(false);
            setQuestionsToShow(questions);
            setCurrentQuestion(questions[0]);
            setCurrentPage(1);
            questions[0].Options && setOptions(questions[0].Options);
            if (!isPracticeMode()) {
                setSelectedOption(getAnswerToQuestion(questions[0]));
            } else {
                if (questions[0].type == 'PROGRAM') {
                    clearEditor();
                }
            }
        }
    }, [questions]);

    useEffect(() => {
        answers && answers.length && checkIfQuestionHasAnswer();
    }, [answers]);

    useEffect(() => {
        currentQuestion && setCountdown(currentQuestion?.secondsToSolve);
        if (isPracticeMode()) {
            currentQuestion && setSelectedOption('');
        }
        // clear the solution
        setSolutionFromGPT('');
        checkIfQuestionHasAnswer();
    }, [currentQuestion]);

    useEffect(() => {
        if (isPracticeMode()) {
            let timer;
            if (countdown > 0) {
                timer = setInterval(() => {
                    setCountdown((currentValue) => currentValue - 1);
                }, 1000);
            }
            if (countdown == 0) {
                // submit the answer
                handleOptionChange(0);
            }
            return () => clearInterval(timer);
        }
    }, [countdown]);

    function handleCodeEditorDidMount(editor) {
        !isPracticeMode() && editor.updateOptions({ readOnly: true });
        editor.onKeyDown((event) => {
            const { keyCode, ctrlKey, metaKey } = event;
            if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
                event.preventDefault();
            }
        });
        editorRef.current = editor;
    }

    function clearEditor() {
        editorRef?.current && editorRef?.current.setValue('');
    }

    const isPracticeMode = () => {
        return mode == 'practice';
    };

    const getAnswerToQuestion = (q) => {
        let returnValue;
        answers.forEach((answer) => {
            if (answer.questionId == q.id) {
                if (q.type == 'SINGLE') {
                    if (answer.answers[0].id == 0) {
                        // this means no option was selected
                        setNoAnswerSelected(true);
                    } else {
                        setNoAnswerSelected(false);
                    }
                    returnValue = answer.answers[0].id;
                } else {
                    setNoAnswerSelected(false);
                    returnValue = answer.answers[0].program;
                }
                answer.marks > 0 ? setIsCorrectAnswer(true) : setIsCorrectAnswer(false);
            }
        });
        return returnValue;
    };

    const checkIfQuestionHasAnswer = () => {
        if (answers && answers.length) {
            let answerFound = false;
            answers.forEach((answer) => {
                if (answer.questionId == currentQuestion?.id) {
                    answerFound = true;
                }
            });
            if (answerFound) {
                setIsUnattempted(false);
            } else {
                setIsUnattempted(true);
            }
        }
    };

    // const randomizeOptions = (opts) => {
    //     const newArray = [...opts];
    //     for (let i = newArray.length - 1; i > 0; i--) {
    //         const j = Math.floor(Math.random() * (i + 1));
    //         const temp = newArray[i];
    //         newArray[i] = newArray[j];
    //         newArray[j] = temp;
    //     }
    //     return newArray;
    // };

    const handleOptionChange = (event) => {
        const selectedOpt = event ? event.target.value : 0;
        setSelectedOption(selectedOpt);

        if (selectedOpt == 0) {
            // this means, if you dont select any answer and time passes by, then submit answer with 0
            answerQuestion();
        }
    };

    const calculateMarks = async () => {
        let totalMarks = 0;
        if (currentQuestion.type == 'PROGRAM') {
            /* call openapi */
            let programWritten = editorRef.current.getValue();
            if (programWritten.length) {
                let input = {
                    answer: programWritten
                };
                const response = await axiosServices.post(`/getSolutionToProgrammingQuestion/${currentQuestion.id}`, input);
                totalMarks = response.data.result.indexOf('Yes') == -1 ? 0 : 1;
                return totalMarks;
            } else {
                totalMarks = 0;
            }
        } else {
            totalMarks = options.find((option) => option.id == selectedOption)?.value || 0;
            return parseInt(totalMarks);
        }
    };

    const answerQuestion = async () => {
        if (isPracticeMode()) {
            setIsLoading(true);
            let writtenAnswers = currentQuestion?.type == 'SINGLE' ? [{ id: selectedOption }] : [{ program: editorRef.current.getValue() }];
            let answerTemplate = {
                assessmentId: assessmentId,
                questionId: currentQuestion?.id,
                subjectId: currentQuestion?.subjectId,
                chapterId: currentQuestion?.chapterId,
                topicId: currentQuestion?.topicId,
                studentId: user.id,
                isComplete: attemptedQuestionsCount + 1 == questionsToShow?.length,
                marks: await calculateMarks(),
                answers: writtenAnswers
            };
            try {
                const response = await axiosServices.post(`/answer`, answerTemplate);
                if (response.status == 201) {
                    setAttemptedQuestionsCount((prevValue) => prevValue + 1);
                    nextQuestion();
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            nextQuestion();
        }
    };

    const nextQuestion = (index = null) => {
        let nextQuestionIndex;
        if (index != null) {
            nextQuestionIndex = index;
        } else {
            nextQuestionIndex = questionsToShow.indexOf(currentQuestion) + 1;
        }
        if (questionsToShow[nextQuestionIndex]) {
            setCurrentQuestion(questionsToShow[nextQuestionIndex]);
            setOptions(questionsToShow[nextQuestionIndex].Options);
            if (!isPracticeMode()) {
                setSelectedOption(getAnswerToQuestion(questionsToShow[nextQuestionIndex]));
            } else {
                if (questionsToShow[nextQuestionIndex].type == 'PROGRAM') {
                    clearEditor();
                }
            }
        } else {
            window.history.back();
        }
    };

    const currentQuestionIndex = () => {
        return questionsToShow.indexOf(currentQuestion) + 1;
    };

    const handlePaginationPageChange = (event, page) => {
        setCurrentPage(page);
        nextQuestion(page - 1);
    };

    const handleReportButtonClick = () => {
        setIsReportModalOpen(true);
    };

    const handleReportSubmit = async (reason) => {
        try {
            let input = {
                studentId: user.id,
                reportedItemId: currentQuestion?.id, // Replace with the actual reported item ID
                reportedItemType: 'QUESTION', // Replace with the actual reported item type
                reason: reason
            };
            const response = await axiosServices.post('/report', input);
            if (response.status == 200) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Question was reported successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'There was a problem while reporting the question. Please try later!',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            console.log('close loader. job done');
        }
    };

    const getOptionAlphabet = (index) => {
        let returnValue;
        switch (index) {
            case 0:
                returnValue = 'A';
                break;
            case 1:
                returnValue = 'B';
                break;
            case 2:
                returnValue = 'C';
                break;
            case 3:
                returnValue = 'D';
                break;
            default:
                break;
        }
        return returnValue;
    };

    const getSolution = async () => {
        setIsSolutionLoading(true);
        try {
            let correctAnswer;
            let input, defaultProgram, defaultLanguage;
            if (currentQuestion.type == 'SINGLE') {
                correctAnswer = currentQuestion?.Options.find((option) => option.value == 1).title;
                input = {
                    questionId: currentQuestion?.id,
                    system: `Question: ${currentQuestion?.title}. Answer: ${correctAnswer}.`,
                    user: `Act as the best teacher in the world and explain the answer to this question step by step, as if you were explaining it to a 10-year-old. Very Important rules to follow - 1. Don't use LaTeX formatting in the explanation. 2. Give me the complete explanation in pure HTML using mathematical symbols, <sup> for superscripts and <sub> for subscripts where appropriate. 3. Never mention titles like - "Here is the explanation for 10 year old, formatted in HTML". 4. Don't use header tags below h3 & div tag. For reference - Here is an example format: <h3>Solution Title</h3><ol><li><b>Step 1: Title</b><p>Description</p></li><li><b>Step 2: Title</b><p>Description</p><ul><li>Point 1</li><li>Point 2</li></ul></li></ol>`
                };
            } else {
                // if question type is program, this we dont send an answer to the question.
                // this is because there can be a default program which is wrong and which need to be corrected.
                correctAnswer = '';
                if (currentQuestion?.props) {
                    defaultLanguage = JSON.parse(currentQuestion?.props).language;
                    if (JSON.parse(currentQuestion?.props).defaultProgram) {
                        defaultProgram = JSON.parse(currentQuestion?.props).defaultProgram;
                    }
                }
                let systemPrompt = `Question: ${currentQuestion?.title}`;
                if (defaultProgram) {
                    systemPrompt += `${defaultProgram}`;
                }
                input = {
                    questionId: currentQuestion?.id,
                    system: systemPrompt,
                    user: `Act as the best teacher in the world and explain the answer to this question step by step, as if you were explaining it to a 10-year-old. Write the correct program and explain considering the programming language as ${defaultLanguage}. Very Important rules to follow - 1. Don't use LaTeX formatting in the explanation. 2. Give me the complete explanation in pure HTML using mathematical symbols, <sup> for superscripts and <sub> for subscripts where appropriate. 3. Never mention titles like - "Here is the explanation for 10 year old, formatted in HTML". 4. Don't use header tags below h3 & div tag. 5. Always display code with proper indentation inside the <pre> & <code> tags. For reference - Here is an example format: <h3>Solution Title</h3><ol><li><b>Step 1: Title</b><p>Description</p></li><li><b>Step 2: Title</b><p>Description</p><ul><li>Point 1</li><code></code></ul></li></ol>`
                };
            }
            await axiosServices.post('/completions', input);
        } catch (error) {
            console.log(error);
        } finally {
            setIsSolutionLoading(false);
        }
    };

    const handleTabChange = (e, newValue) => {
        setNotesOrVideos(newValue);
    };

    return (
        <>
            <Grid spacing={gridSpacing} container justifyContent={'center'} height={'100%'}>
                <Grid item xs={12} sm={12} md={isPracticeMode() ? 8 : 5} height={'100%'}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent sx={{ height: '82%' }}>
                            {isLoading ? (
                                <CircularLoader />
                            ) : (
                                <>
                                    <PerfectScrollbar style={{ height: 375 }}>
                                        <Grid
                                            container
                                            direction={'column'}
                                            height={'100%'}
                                            justifyContent={'space-between'}
                                            flexWrap={'nowrap'}
                                        >
                                            <Grid item>
                                                {questionsToShow ? (
                                                    <>
                                                        <Grid container direction={matchDownSm || matchDownXs ? 'column-reverse' : 'row'}>
                                                            <Grid item xs={12} sm={12} md={isPracticeMode() ? 10 : 12}>
                                                                <Typography variant="h4" color={COLOURS.BRAND_PRIMARY}>
                                                                    <div
                                                                        onCopy={(e) => {
                                                                            e.preventDefault();
                                                                            return false;
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: `${currentQuestionIndex()}. ${currentQuestion.title}`
                                                                        }}
                                                                    ></div>
                                                                </Typography>
                                                            </Grid>
                                                            {isPracticeMode() && (
                                                                <Grid item xs={12} sm={12} md={2} mb={matchDownSm || matchDownXs ? 2 : 0}>
                                                                    <TimerCard
                                                                        countdown={countdown}
                                                                        totalQuestions={questionsToShow.length}
                                                                        currentQuestionIndex={currentQuestionIndex()}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                        {!isPracticeMode() && (
                                                            <Grid
                                                                container
                                                                display={'flex'}
                                                                justifyContent={'end'}
                                                                alignItems={'center'}
                                                                mt={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    display="flex"
                                                                    alignItems={'center'}
                                                                    justifyContent={'center'}
                                                                    sx={{
                                                                        borderTopRightRadius: '5px',
                                                                        borderBottomRightRadius: '5px',
                                                                        color: '#fff',
                                                                        background: isUnattempted
                                                                            ? theme.palette.grey[600]
                                                                            : isCorrectAnswer
                                                                              ? theme.palette.success.dark
                                                                              : noAnswerSelected
                                                                                ? theme.palette.primary.main
                                                                                : theme.palette.error.main,
                                                                        top: '50%',
                                                                        position: 'fixed',
                                                                        left: matchDownSm ? 0 : drawerOpen ? drawerWidth : 72,
                                                                        zIndex: 1200,
                                                                        opacity: 0.8
                                                                    }}
                                                                    py={0.25}
                                                                    px={1}
                                                                >
                                                                    {isUnattempted ? (
                                                                        <>
                                                                            <ErrorOutlineOutlined />
                                                                            &nbsp;Not Attempted
                                                                        </>
                                                                    ) : isCorrectAnswer ? (
                                                                        <>
                                                                            <CheckCircleOutline />
                                                                            &nbsp;Answer is correct
                                                                        </>
                                                                    ) : noAnswerSelected ? (
                                                                        <>
                                                                            <ErrorOutlineOutlined />
                                                                            &nbsp;Not Answered
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <CancelOutlined />
                                                                            &nbsp;Answer is wrong
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        <FormControl sx={{ marginTop: 2 }} fullWidth>
                                                            <RadioGroup
                                                                aria-label="options"
                                                                onChange={handleOptionChange}
                                                                name="options-radio-group"
                                                                value={selectedOption}
                                                                style={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns:
                                                                        matchDownSm || matchDownXs ? 'repeat(1, 1fr)' : 'repeat(1, 1fr)'
                                                                }}
                                                            >
                                                                {options?.map((option, index) => (
                                                                    <FormControlLabel
                                                                        disabled={!isPracticeMode()}
                                                                        key={option.id}
                                                                        value={option.id}
                                                                        control={<Radio style={{ display: 'none' }} />}
                                                                        label={
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: `${getOptionAlphabet(index)}. ${option.title}`
                                                                                }}
                                                                                style={{ fontWeight: 'bold' }}
                                                                            ></div>
                                                                        }
                                                                        sx={{
                                                                            border: isPracticeMode()
                                                                                ? '1px solid #4a79c9'
                                                                                : '1px solid #ddd',
                                                                            borderRadius: '10px',
                                                                            padding: '15px',
                                                                            margin: '5px',
                                                                            background:
                                                                                !isPracticeMode() && !isUnattempted
                                                                                    ? option.value == 1
                                                                                        ? theme.palette.success.light
                                                                                        : option.id == selectedOption
                                                                                          ? theme.palette.error.light
                                                                                          : null
                                                                                    : option.id == selectedOption &&
                                                                                      theme.palette.primary.dark,

                                                                            color:
                                                                                option.id == selectedOption
                                                                                    ? theme.palette.primary.light
                                                                                    : null,
                                                                            '&:hover': {
                                                                                background:
                                                                                    isPracticeMode() &&
                                                                                    option.id != selectedOption &&
                                                                                    theme.palette.primary.light
                                                                            }
                                                                        }}
                                                                    />
                                                                ))}
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {currentQuestion?.type == 'PROGRAM' && (
                                                            <>
                                                                <Editor
                                                                    onMount={handleCodeEditorDidMount}
                                                                    height={isPracticeMode() ? '350px' : '260px'}
                                                                    theme="vs-dark"
                                                                    value={!isPracticeMode() && selectedOption}
                                                                    language={JSON.parse(currentQuestion?.props).language}
                                                                    options={{ contextmenu: false }}
                                                                    defaultValue={
                                                                        JSON.parse(currentQuestion?.props).defaultProgram &&
                                                                        JSON.parse(currentQuestion?.props).defaultProgram
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Typography variant="h4">
                                                        {isPracticeMode()
                                                            ? 'You have exhausted all the questions, while we add new questions try practicing questions from another topic/skill.'
                                                            : 'You have not answered any questions yet!'}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </PerfectScrollbar>
                                </>
                            )}
                        </CardContent>
                        {questionsToShow && !isLoading && (
                            <>
                                <CardActions>
                                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            sm={12}
                                            lg={4}
                                            display={'flex'}
                                            justifyContent={matchDownSm ? 'start' : 'center'}
                                        >
                                            <MuiTooltip title="Report Question">
                                                <Button
                                                    fullWidth
                                                    onClick={() => handleReportButtonClick()}
                                                    variant="contained"
                                                    endIcon={<Flag />}
                                                    color="warning"
                                                >
                                                    Report
                                                </Button>
                                            </MuiTooltip>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={8}
                                            sm={12}
                                            lg={8}
                                            display={'flex'}
                                            justifyContent={matchDownSm ? 'center' : 'flex-end'}
                                        >
                                            {isPracticeMode() ? (
                                                <Button
                                                    endIcon={
                                                        questionsToShow?.indexOf(currentQuestion) == questionsToShow?.length - 1 ? (
                                                            <Check />
                                                        ) : (
                                                            <KeyboardArrowRight />
                                                        )
                                                    }
                                                    variant="contained"
                                                    color={
                                                        questionsToShow?.indexOf(currentQuestion) == questionsToShow?.length - 1
                                                            ? 'success'
                                                            : 'primary'
                                                    }
                                                    onClick={() => answerQuestion()}
                                                >
                                                    {questionsToShow?.indexOf(currentQuestion) == questionsToShow?.length - 1
                                                        ? 'Submit'
                                                        : 'Next'}
                                                </Button>
                                            ) : (
                                                <Pagination
                                                    siblingCount={0}
                                                    page={currentPage}
                                                    count={questionsToShow?.length}
                                                    color="primary"
                                                    shape="rounded"
                                                    onChange={(event, page) => handlePaginationPageChange(event, page)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </>
                        )}
                    </Card>
                </Grid>
                {!isPracticeMode() && (
                    <Grid item xs={12} sm={12} md={7} height={'100%'}>
                        <MainCard content={false} style={{ height: '100%' }}>
                            <CardContent sx={{ p: 0 }}>
                                <Tabs value={notesOrVideos} onChange={handleTabChange} sx={{ mb: 1 }}>
                                    <Tab icon={<IconRobot />} iconPosition="start" label={'AI Explanation'} />
                                    <Tab icon={<SmartDisplayOutlined />} iconPosition="start" label={'Video'} />
                                    <Tab icon={<DescriptionOutlined />} iconPosition="start" label={'Notes'} />
                                </Tabs>
                                <TabPanel value={notesOrVideos} index={0} style={{ padding: '0px 15px 0px 15px' }}>
                                    {isSolutionLoading ? (
                                        <CircularLoader />
                                    ) : (
                                        <>
                                            <PerfectScrollbar style={{ height: 397 }}>
                                                {/* User Id 1 is Ajith, User Id 2 is Abhijith. */}
                                                {/* If the question has a solution or not, if Ajith or Abhijith logs in, the button should be visible. */}
                                                {/* If other users login, if there is no solution, then show the button. */}
                                                {user.id == 2 && (
                                                    <Button variant="contained" onClick={() => getSolution()}>
                                                        Get detailed solution
                                                    </Button>
                                                )}
                                                {currentQuestion?.solution ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: currentQuestion?.solution ? currentQuestion?.solution : solutionFromGPT
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
                                                        <Grid item>
                                                            <img src={noSolutionImage} alt="Not found" width={'150px'} />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </PerfectScrollbar>
                                        </>
                                    )}
                                </TabPanel>
                                <TabPanel value={notesOrVideos} index={1} style={{ padding: '0px 15px 0px 15px' }}>
                                    <MediaCard selectedElement={selectedElement} chapterMedia={chapterMedia} />
                                </TabPanel>
                                <TabPanel value={notesOrVideos} index={2} style={{ padding: '0px 15px 0px 15px' }}>
                                    <DocumentsCard selectedElement={selectedElement} chapterMedia={chapterMedia} />
                                </TabPanel>
                            </CardContent>
                        </MainCard>
                    </Grid>
                )}
            </Grid>
            <ReportDialog open={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} onSubmit={handleReportSubmit} />
        </>
    );
};

Assessment.propTypes = {
    questions: PropTypes.array,
    answers: PropTypes.array,
    mode: PropTypes.string,
    selectedElement: PropTypes.object,
    chapterMedia: PropTypes.object
};

export default Assessment;
